import { render, staticRenderFns } from "./popfeeds.vue?vue&type=template&id=6462b606&scoped=true&"
import script from "./popfeeds.vue?vue&type=script&lang=js&"
export * from "./popfeeds.vue?vue&type=script&lang=js&"
import style0 from "./popfeeds.vue?vue&type=style&index=0&id=6462b606&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6462b606",
  null
  
)

export default component.exports